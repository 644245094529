body {
  width: 100%;
  font-size: 100%;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: $secondaryColor;
  a {
    text-decoration: none;
  }
  &:link, &:visited {
    color: $secondaryColor;
  }
}

a:hover,
a:focus {
  text-decoration: underline;
}

h1, h2, .social {
  margin: .2em 0 !important;
}

.post-description h1 {
  font-size:2rem !important;
}

h1:not(.title):not(.content-subhead) {
  font-weight: bold;
  font-size: 3rem !important;
  line-height: 32px;
  .post-description &{
    font-size: 1.5rem !important;
  }
  &.header {
    font-size: 2em !important;
    margin: .67em 0;
  }
}

h2 {
  font-weight: bold;
  &:not(.brand-tagline) {
    font-size: 2rem;
    line-height: 32px;
  }
}

h3 {
  font-weight: 100;
  font-size: 2rem;
  line-height: 24px;
}

h4 {
  font-size: 1.8rem;
  line-height: 24px;
}

::selection {
  background: $quaternaryColor;
}

p {
  line-height: 1.2;
}

/* LAYOUT CSS */
.pure-img-responsive {
  max-width: 100%;
  height: auto;
}

#layout {
  padding: 0;
}

.header {
  text-align: center;
  top: auto;
  margin: 3em auto;
}

.sidebar {
  //background: rgb(61, 79, 93);
  color: $primaryColor;
  border-bottom: $border;
  border-right: $border;
}

.brand-title,
.brand-tagline {
  margin: 0;
}

.brand-title {
  text-transform: uppercase;
}

.brand-tagline {
  font-weight: 300;
  color: black;
}

.nav-list {
  margin: .5em 0;
  padding: 0;
  list-style: none;
}

.nav-item {
  display: inline-block;
  *display: inline;
  zoom: 1;
  a {
    background: transparent;
    border: 2px solid $tertiaryColor;
    color: $primaryColor;
    margin-top: 1em;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-size: 85%;
    padding: .5em;
    &:hover,
    &:focus {
      border: 2px solid $quaternaryColor;
      text-decoration: none;
    }
    &:active {
      background: $tertiaryColor;
    }
  }
}

.content-subhead {
  text-transform: uppercase;
  color: #aaa;
  border-bottom: 1px solid #eee;
  padding: 0.4em 0;
  font-size: 80%;
  font-weight: 500;
  letter-spacing: 0.1em;
}

.content {
  padding: 2em 1em 0;
}

.post {
  padding-bottom: 2em;
  .read-more {
    margin-top:5px;
  }
}

.post-title {
  font-size: 2em;
  color: #222;
  margin-bottom: 0.2em;
}

.post-avatar {
  border-radius: 50px;
  float: right;
  margin-left: 1em;
}

.post-description {
  color: #444;
  line-height: 1.8em;
}

.post-meta {
  color: #999;
  font-size: 90%;
  margin: 0 0 5px 0;

}

.post-category {
  margin: 0 0.1em;
  padding: 0.3em 1em;
  color: #fff !important;
  background: #999;
  font-size: 80%;
  text-decoration: none;
  display:inline-block;
  &:hover {
    background: $quaternaryColor;
    text-decoration: none;
    color: $primaryColor !important;
  }
}

.post-category-design {
  background: #5aba59;
}

.post-category-pure {
  background: #4d85d1;
}

.post-category-yui {
  background: #8156a7;
}

.post-category-js {
  background: #df2d4f;
}

.post-images {
  margin: 1em 0;
}

.post-image-meta {
  margin-top: -3.5em;
  margin-left: 1em;
  color: #fff;
  text-shadow: 0 1px 1px #333;
}

.footer {
  text-align: center;
  padding: 2em 0;
}

.footer {
  a {
    color: $secondaryColor;
    font-size: 80%;
  }
  .pure-menu a {
    &:hover,
    &:focus {
      background: none;
    }
  }
}

@media (min-width: 64em) {
  .content {
    padding: 2em 3em 0;
    margin-left: 25%;
  }

  .header {
    margin: 80% 2em 0;
    text-align: right;
  }

  .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
  }
}

ul.social {
  list-style: none;
  padding: 0;
  li {
    display: inline-block;
    zoom: 1.5;
    padding: 5px;
    i {
      color: darken($tertiaryColor, 30%);
      &:hover {
        color: $quaternaryColor;
      }
    }
  }
}

img {
  display: block;
  margin: 1em auto;
}

.alert {
  position: relative;
  margin-bottom: 1em;
  padding: 1em;
  background: #ccc;
  border-radius: 3px;
}

.alert-error {
  background-color: $quaternaryColor;
  color: #000;
  a:hover {
    color: white;
  }
}

.post-tags {
  padding: 10px 0;
}

a.button {
  background: transparent;
  border: 2px solid $tertiaryColor;
  color: $primaryColor;
  margin-top: 1em;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-size: 85%;
  padding: .5em;
  &:hover,
  &:focus {
    border: 2px solid $quaternaryColor;
    text-decoration: none;
  }
  &:active {
    background: $tertiaryColor;
  }
}

#toolbar {
  margin-bottom: 1em;
}

article {
  padding: 10px 0;
}

.title {
  font-size: 4.8rem !important;
  line-height: 56px;
}

.button-bar {
  text-align: center;
  margin: 2em 0;
}

.icon {
  vertical-align: bottom;
  display: inline-block;
  position: absolute;
  i {
    vertical-align: top;
    margin-top: 6%;
    margin-left: 0.7%;
    position: relative;
  }
}

.img-round {
  border-radius: 80%;
}

.img-borderd {
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 5px;
}

.small-img {
  width: 10em;
  height: auto;
}

.post-content {
  > * {
    margin-bottom: 1em;
  }
}
#fixedbutton {
  position: fixed;
  top: 0px;
  right: 10px;
  background-color: white;
}

iframe{
  display:block;
  margin:auto;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}